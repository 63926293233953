.policy{
  color: #475569;
  padding: 60px;
  border-radius: 12px;
  background-color: #fff;
  .big_title{
    font-size: 34px;
    font-weight: 900;
    margin-bottom: 40px;
  }
  .title{
    font-size: 24px;
    font-weight: 700;
    margin: 32px 0 12px;
  }
  .text{
    font-size: 16px;
    font-weight: 400;
    margin: 8px 0;
    line-height: 28px;
  }
  table, td, th{
    border: 1px solid #000;
    border-collapse: collapse;
    min-width: 150px;
    padding: 5px 20px;
    td{
      font-size: 16px;
      vertical-align: top;
    }
  }
  ul{
    padding-left: 1.2rem;
    li{
      list-style: disc;
    }
  }
}