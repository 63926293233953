@import "../../../assets/mixins/adaptiveFontMixin";
.who_needs_our_service {
  .item {
    border-radius: 24px;
    background-size: cover;
    position: relative;
    display: flex;
    transition: .7s;
    &:hover{
      transform: scale(1.05);
    }
    .item_content {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgb(255 255 255 / 80%);
      text-align: center;
      padding: calc(1px + 25 * ((100vw - 320px) / (1820 - 320)));
      color: #0f172a;
      @include adaptiv-font(24, 16);
      font-weight: 400;
      border-radius: 20px;
      width: 87%;
      margin: 20px auto;
    }
  }
}