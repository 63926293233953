@import "../../assets/styles/variables";
.how_to_the_pay{
  .how_to_the_pay_items{
    overflow: hidden;
    border-radius: 20px;
    background-color: $sectionBGColor;
    .how_to_pay_image{
      padding: 95px 80px;
      background-color: $blue;
      border-radius: 20px;
    }
    .how_to_pay_content{
      padding: 60px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}
//Responsive
@media screen and (max-width: 768px){
  .how_to_the_pay{
    .how_to_the_pay_items{
      .how_to_pay_content{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &:nth-child(2){
          text-align: center;
        }
      }
    }
  }
}