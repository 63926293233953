@import "../../../assets/styles/variables";
.payment_methods{
  .universal_payment{
    height: 50px;
    //margin-bottom: 15px;
    iframe{
      height: inherit;
      border-radius: 8px;
    }
  }
  .gpay_payment{
    height: 45px;
    background-color: #000;
    border-radius: 8px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    img{
      height: 23px;
    }
  }
  .card{
    height: 50px;
    border-radius: 8px;
    //overflow: hidden;
    button{
      height: inherit;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .card_content{
    margin: 10px 0;
    iframe{
      border: 1px solid $blue!important;
      border-top-left-radius: 8px!important;
      border-top-right-radius: 8px!important;
      height: 100%!important;
      margin-bottom: 5px!important;
    }
    &.show{
      display: none;
    }
  }
}
