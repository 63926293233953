.tipssi_qr_component{
  padding: 40px 71px;
  border-radius: 12px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #0666EB;
  font-weight: 600;
  .tipssi_qr_image{
    width: 120px;
    margin: 0 auto;
  }
  .tipssi_qr_title{
    font-size: 30px;
    margin: 10px 0 15px;
  }
  .tipssi_qr_subtitle{
    font-size: 20px;
    margin-bottom: 55px;
  }
  .tipssi_qr_smallSubtitle{
    font-size: 24px;
    color: #939292;
    font-weight: 400;
    margin-bottom: 10px;
    line-height: 30px;
  }
  .field{
    border-radius: 8px;
    width: 100%;
    background-color: #F0F2F9;
    input{
      padding: 5px 14px!important;
      font-size: 25px;
      font-weight: 600;
      text-align: center;
    }
    fieldset{
      border: none;
    }
  }
}