@import "../../../assets/mixins/adaptiveFontMixin";
@import "../../../assets/styles/variables";
.for_whom_this_service{
  background-color: $sectionBGColor;
  .for_whom_this_service_title{
    @include adaptiv-font(36, 30);
    text-align: center;
    font-weight: 600;
    margin-bottom: 40px;
  }
  .for_whom_this_service_items{
    .for_whom_this_service_item{
      border-radius: 24px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 25px;
      background-color: #fff;
      transition: .5s;
      &:hover{
        transform: scale(1.05);
      }
      .for_whom_this_service_item_img{
        border-radius: 12px;
        overflow: hidden;
      }
      .for_whom_this_service_item_title{
        @include adaptiv-font(30, 20);
        line-height: 20px;
        margin: 28px 0 20px;
        font-weight: 600;
      }
    }
  }
}