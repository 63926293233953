.how_much_does_it_cost{
  .how_much_does_it_cost_img{
    img{
      max-height: 570px;
      object-fit: contain;
    }
  }
}
@media screen and (max-width: 960px) {
  .how_much_does_it_cost{
    .how_much_does_it_cost_img{
      display: none!important;
    }
  }
}