@import "../../assets/styles/variables";
.business{
  background-color: $sectionBGColor;
  .business_subtitle{
    font-size: 20px;
    margin: 48px auto 60px;
    line-height: calc(20px + 8 * ((100vw - 320px) / (1860 - 320)));
    max-width: 800px;
    text-align: center;
    font-weight: 400;
    color: #334155;
  }
  .business_items{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
  }
  .business_item{
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    .business_item_title{
      margin-bottom: 12px;
    }
    .business_item_subtitle{
      font-size: 20px;
      font-weight: 400;
      line-height: calc(20px + 8 * ((100vw - 320px) / (1860 - 320)));
    }
  }
}