.modal_title{
  font-size: 16px!important;
}

.modal_body{
  background: url("../../assets/images/background-substrate.png") no-repeat center/cover;
  height: 457px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .modal_body_image{
    background: url("../../assets/images/edit-click.png") no-repeat center/cover;
    width: 450px;
    height: 100%;
    cursor: pointer;
    img{
      width: 100%;
      height: 100%;
    }
  }
}

.modal_footer{
  button, label{
    text-transform: capitalize;
    border-radius: 8px!important;
    box-shadow: none!important;
    font-size: 16px;
    padding: 10px 50px !important;
    background-color: rgb(6, 102, 235) !important;
    color: rgb(255, 255, 255) !important;
    //&:first-child{
    //  background-color: rgb(240, 242, 249) !important;
    //  color: rgb(0, 0, 0) !important;
    //}
    //&:last-child{
    //  background-color: rgb(6, 102, 235) !important;
    //  color: rgb(255, 255, 255) !important;
    //}
  }
}