@import "../../../assets/styles/variables";
.join_our_service{
  background-color: $blue;
  color: #fff;
  .btn{
    background-color: #fff;
    font-size: 16px;
    font-weight: 600;
    padding: 6px 25px;
    color: #0F172A;
    border-radius: 8px;
    transition: .2s;
    display: flex;
    width: fit-content;
    margin-top: 40px;
    margin: 0 auto;
    &:hover{
      background-color: #efefef;
    }
  }
}