.footer{
  font-size: 12px;
  color: #475569;
  border-top: 1px solid #262627;
  position: sticky;
  z-index: 10;
  .main_footer{
    .logo{
      width: 95px;
    }
    padding: 12px;
    .icon{
      a {
        display: flex;
        align-items: center;
        font-size: inherit;
        color: inherit;
        img {
          margin-right: 6px;
        }
      }
    }
    .social_icons{
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 22px;
      .whatsapp,
      .facebook,
      .instagram,
      .youtube{
        transition: .2s;
      }
      .whatsapp{
        &:hover{
          color: #40E15D;
        }
      }
      .facebook{
        &:hover{
          color: #0D6EFD;
        }
      }
      .instagram{
        &:hover{
          color: #C944C1;
        }
      }
      .youtube{
        &:hover{
          color: #FF0000;
        }
      }
      .tiktok{
        a{
          padding: 2.5px;
          border-radius: 4.5px;
          font-size: 18px;
          transition: .2s;
          &:hover{
            background-color: #fff;
            color: #1A1620;
          }
        }
      }
    }
  }

  .pre_footer{
    background-color: #F8FAFC;
    .pre_footer_content{
      padding: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .footer_menu{
        display: flex;
        align-items: center;
        gap: 140px;
      }
    }
  }
}
//Responsive
@media screen and (max-width: 900px){
  .footer{
    .main_footer{
      flex-direction: column;
      gap: 30px;
    }
    .pre_footer{
      .pre_footer_content{
        flex-direction: column-reverse;
        gap: 30px;
        .footer_menu{
          flex-direction: column;
          gap: 30px;
        }
      }
    }
  }
}