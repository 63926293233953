.container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: #0666EB;
  text-align: center;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  padding: 20px;
  box-sizing: border-box;
  .icon{
    font-size: 4rem;
    margin-bottom: 1rem;
    color: #FF9100;
  }
  .heading{
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  .paragraph {
    font-size: 1.5rem;
    max-width: 600px;
    line-height: 1.6;
  }
  .button{
    color: #fff;
  }
}