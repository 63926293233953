@import "../../assets/styles/variables";
.withdrawal_of_funds{
  background-color: $sectionBGColor;
  .withdrawal_of_funds_items{
    border-radius: 20px;
    background-color: #fff;
    overflow: hidden;
    .withdrawal_of_funds_content{
      padding: 60px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  .withdrawal_of_funds_image{
    border-radius: 20px;
  }
}
//Responsive
//@media screen and (max-width: 768px){
//  .withdrawal_of_funds{
//    .withdrawal_of_funds_items{
//      //flex-direction: column;
//      .withdrawal_of_funds_content{
//        flex-direction: column;
//        align-items: center;
//        justify-content: center;
//        &:nth-child(1){
//          text-align: center;
//        }
//      }
//      .withdrawal_of_funds_image{
//        border-radius: 20px;
//        overflow: hidden;
//      }
//    }
//  }
//}