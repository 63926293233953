@import "../../assets/styles/variables";

.thankyou_component {
  padding: 40px calc(20px + 51 * ((100vw - 320px) / (1860 - 320)));
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

  .thank_you_title {
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 16px;
    color: #939292;
  }

  .thank_you_subtitle {
    font-size: 30px;
    font-weight: 600;
    color: #0666EB;
    text-align: center;
    line-height: 33px;
    margin-bottom: 20px;
  }

  .thank_you_image {
    width: 100px;
    margin: 0 auto;
  }

  .thank_you_icon {
    margin: 32px auto 4px auto;
  }

  .thank_you_social {
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: center;
    margin-bottom: 15px;

    .thank_you_social_icon {
      .facebook {
        color: #0866FF;
        font-size: 35px;
      }

      .instagram {
        color: #C944C1;
        font-size: 35px;
      }

      .whatsapp {
        color: #40E15D;
        font-size: 35px;
      }

      .linkedin {
        color: #0A66C2;
        font-size: 35px;
      }

      .tiktok {
        color: #000;
        font-size: 35px;
      }

      .youtube {
        color: #FF0000;
        font-size: 35px;
      }
    }
  }

  .download_check {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 32px;

    button {
      padding: 4px 12px !important;
      background-color: $blue;
      border-radius: 8px;
    }

    .download_button {
        button {
          background-color: transparent!important;
          border: 1px solid $blue;
          border-radius: 4px!important;
          color: $blue;
        }
        button:hover {
          background-color: $blue!important;
          color: #fff;
        }
    }
  }
}