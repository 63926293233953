@import "../../assets/styles/variables";
.it_is_convenient{
  background-color: $sectionBGColor;
  .it_is_convenient_items {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 24px;
    margin-top: 60px;
    .it_is_convenient_item {
      background-color: #fff;
      padding: 24px 30px;
      border-radius: 24px;
      display: flex;
      align-items: center;
      width: calc(249px + 427 * ((100vw - 320px) / (1860 - 320)));
      .it_is_convenient_item_image {
        margin-right: 30px;
        background-color: #F0F2F9;
        border-radius: 16px;
        width: 110px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 54px;
          height: 54px;
        }
      }
    }
  }
}
//Responsive
@media screen and (max-width: 768px){
  .it_is_convenient{
    .it_is_convenient_items {
      .it_is_convenient_item {
        width: 100%;
      }
    }
  }
}