@import "../../../assets/styles/variables";
.profile_qr{
  background-color: #fff;
  padding: 40px 44px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: $profilePageBorderRadius;
  position: relative;
  .profile_qr_numbers{
    font-size: 24px;
    font-weight: 700;
    margin-top: 20px;
    span{
      color: #0666EB;
    }
  }
  .profile_qr_title{
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  .profile_qr_go_payment_page{
    margin-top: 16px;
    font-weight: 600;
    color: $blue;
    text-align: center;
  }
  .profile_qr_share{
    font-size: 12px;
    color: $blue;
    font-weight: 600;
    margin: 12px 0;
    cursor: pointer;
  }
  .profile_qr_text{
    font-size: 12px;
    color: #475569;
    font-weight: 400;
    margin: 0;
  }
}