@import "../../../assets/styles/variables";
.transfer_items{
  .transfer_item{
    border-radius: $profilePageBorderRadius;
    .transfer_title{
      font-size: 21px;
      font-weight: 700;
      text-align: center;
      margin-bottom: 10px;
    }
    .transfer_subtitle{
      font-size: 17px;
      font-weight: 700;
      text-align: center;
      margin-bottom: 20px;
    }
    .card_input{
      border: 1px solid #C4C4C4;
      border-radius: 8px;
      padding: 14px;
      width: 100%;
      background-color: transparent;
      color: #000;
    }
  }
  .transfer_conditions{
    border-radius: 8px;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
    .transfer_conditions_title{
      font-weight: 700;
      font-size: 14px;
    }
    .transfer_conditions_list{
      list-style: decimal;
      padding: 17px;
      font-size: 14px;
      font-weight: 400;
      .transfer_conditions_list_item{
        margin-bottom: 16px;
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
  }
  .transfer_conditions_danger_text{
    color: rgb(100, 116, 139);
  }
}