@import "../../../assets/styles/variables";
.why_you_should_use_our_service{
  background-color: $sectionBGColor;
  .why_you_should_use_our_service_items{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 40px;
    .why_you_should_use_our_service_item_block{
      margin-bottom: 24px;
      .why_you_should_use_our_service_item{
        background-color: #fff;
        border-radius: 20px;
        padding: 20px 30px;
        height: 100%;
        min-height: 220px;
        .why_you_should_use_our_service_item_img{
          height: 40px;
          width: 40px;
        }
        .why_you_should_use_our_service_item_text{
          font-size: 18px;
          line-height: calc(20px + 8 * ((100vw - 320px) / (1860 - 320)));
          font-weight: 400;
          margin-top: 24px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px){
  .why_you_should_use_our_service_item_block{
    width: 375px;
  }
}