@import "../../assets/styles/variables";
@import "../../assets/mixins/adaptiveFontMixin";

.registration_qr_payment {
  .registration_qr_payment_item {
    position: relative;
    margin-bottom: 50px;
    overflow: hidden;

    .bg {
      position: absolute;
      z-index: 1;
      left: 0;
    }

    padding: calc(10px + 40 * ((100vw - 320px) / (1860 - 320))) calc(10px + 40 * ((100vw - 320px) / (1860 - 320))) calc(10px + 110 * ((100vw - 320px) / (1860 - 320))) calc(10px + 40 * ((100vw - 320px) / (1860 - 320)));
    border-radius: $borderRadius;

    .registration_qr_payment_item_title {
      margin-bottom: 72px;
      position: sticky;
      z-index: 10;
    }

    .registration_qr_payment_item_content {
      position: sticky;
      z-index: 10;
      padding: 20px 20px 20px calc(1px + 119 * ((100vw - 320px) / (1860 - 320)));
      display: flex;
      align-items: center;
      gap: calc(10px + 90 * ((100vw - 320px) / (1860 - 320)));

      .registration_qr_payment_item_content_text {
        max-width: calc(232px + 122 * ((100vw - 320px) / (1860 - 320)));
        color: #1e293b;
        @include adaptiv-font(18, 16);
        font-weight: 400;
        line-height: calc(24px + 4 * ((100vw - 320px) / (1860 - 320)));


      }
    }
  }
  .qr_code_block{
    background-color: $blue;
    border-radius: $borderRadius;
    display: flex;
    .qr_code_block_left {
      padding: 50px;
      .registration_qr_payment_item_title {
        margin-bottom: 30px;
      }

      .registration_qr_payment_item_content {
        padding: 20px;
      }
    }
    .qr_code_block_right{
      background-color: #fff;
      border-radius: $borderRadius;
      padding: 50px;
      color: #000;
      display: flex;
      flex-direction: column;
      align-items: center;
      .qr_code_block_right_subtitle{
        max-width: 394px;
        margin: 36px 0;
        font-size: 18px;
        line-height: 28px;
        div{
          margin-top: 16px;
        }
      }
      .qr_code_block_right_img{
        width: 175px;
      }
    }
  }
  .payment_block{
    .payment_block_left{
      background-color: #fff;
      border-radius: $borderRadius;
      overflow: hidden;
      padding: 50px;
      .registration_qr_payment_item_content{
        padding: 20px;
        .registration_qr_payment_item_content_text{
          color: #000;
        }
      }
    }
    .payment_block_right{
      background-color: #64748B;
      border-radius: $borderRadius;
      overflow: hidden;
      padding: 50px;
      .payment_block_right_subtitle{
        color: #fff;
        font-size: 18px;
        line-height: 28px;
      }
      .payment_block_right_img{
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .registration_qr_payment_item_title{
      color: $blue;
    }
  }
  .withdrawing_block{
    background-color: $blue;
    border-radius: $borderRadius;
    padding: 50px;
    .withdrawing_block_content{
      .withdrawing_block_content_items{
        margin-top: 52px;
        display: flex;
        flex-direction: column;
        .withdrawing_block_content_item{
          background-color: #fff;
          border-radius: $borderRadius;
          padding: 24px 30px;
          color: #000;
          gap: 32px;
          margin-bottom: 48px;
          &:last-child{
            margin-bottom: 0;
          }
          .withdrawing_block_content_item_img{
            width: 51px;
          }
        }
      }
      .withdrawing_block_content_img{
        img{
          height: 100%;
          max-height: 370px;
          object-fit: contain;
        }
      }
    }
  }
}

.registration_qr_payment_item_content {
  position: sticky;
  z-index: 10;
  padding: 20px 20px 20px calc(1px + 119 * ((100vw - 320px) / (1860 - 320)));
  display: flex;
  align-items: center;
  gap: calc(10px + 90 * ((100vw - 320px) / (1860 - 320)));
}

.registration_qr_payment_item_content_img {
  width: 192px;

  img {
    max-height: 400px;
    height: 100%;
  }
}

.arrow {
  width: 60px;
  margin-top: 36px;
  transform: rotate(90deg);
}

//Responsive
@media screen and (max-width: 768px) {
  .arrow {
    margin: 36px auto 0;
  }
  .qr_code_block{
    flex-direction: column;
    .qr_code_block_right{
      width: 100%;
    }
  }
  .payment_block{
    flex-direction: column;
    .payment_block_left,
    .payment_block_right{
      width: 100%;
    }
  }
  .withdrawing_block{
    background-color: $blue;
    border-radius: $borderRadius;
    padding: 50px;
    .withdrawing_block_content{
      flex-direction: column;
      align-items: center;
      .withdrawing_block_content_items{
        margin: 52px 0;

        align-items: center;
      }
    }
  }
  .registration_qr_payment_item_content {
    flex-direction: column;
    .registration_qr_payment_item_content_text {
      width: 100%;
    }
  }
}

