$menuItemColor: #475569;
$menuItemHoverBackgroundColor: #F1F2F3;
$letterSpaceing: .0892857143em;
$blue: #0666EB;
$blueHover: #065ad3;
$sectionBGColor: #F0F2F9;
$borderRadius: 20px;
$profilePageGap: 24px;
$profilePageBorderRadius: 16px;
$profilePagePadding: 40px 32px;

