@import "../../assets/styles/variables";
@import "../../assets/mixins/adaptiveFontMixin";

.how_its_work{
  background-color: $sectionBGColor;
  .how_its_work_items{
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .how_its_work_items_body{
      margin: 12px;
      width: 370px;
      .how_its_work_item{
        width: 100%;
        height: auto;
        background-color: #fff;
        border-radius: 24px;
        padding: 40px 35px;
        .image{
          width: 100%;
          height: auto;
          img{
            max-width: 100%;
            height: 450px;
            margin: 0 auto;
            display: block;
            object-fit: contain;
          }
        }
        .counter{
          display: flex;
          align-items: center;
          margin: 24px 0;
          .counter_number{
            color: $blue;
            @include adaptiv-font(64, 40);
            line-height: calc(44px + 31 * ((100vw - 320px) / (1860 - 320)));;
            letter-spacing: .2px;
            font-weight: 600;
          }
          .counter_img{
            max-width: calc(40px + 20 * ((100vw - 320px) / (1860 - 320)));
            margin-left: calc(20px + 30 * ((100vw - 320px) / (1860 - 320)));
          }
        }
        .title{
          margin-bottom: 16px;
        }
        .description{
          @include adaptiv-font(18, 14);
          line-height: calc(18px + 10 * ((100vw - 320px) / (1860 - 320)));
        }
      }
    }
  }
}
//Responsive
@media screen and (max-width: 860px){
  .how_its_work {
    .how_its_work_items {
      flex-direction: column!important;
      align-items: center!important;
      justify-content: center!important;
      .how_its_work_items_body{
        width: 80%;
        .how_its_work_item{
          align-items: center;
          .counter{
            .counter_img{
              display: none;
            }
          }
          .description{
            text-align: center;
          }
        }
        .counter_img_bottom{
          display: block!important;
          max-width: calc(50px + 10 * ((100vw - 320px) / (1860 - 320)));
          transform: rotate(90deg);
          margin-top: 25px;
        }
      }
    }
  }
}