@import "../../../assets/styles/variables";

.print_item_body{
  padding: 12px;
  &:first-child,
  &:nth-child(2),
  &:nth-child(3),{
    padding-top: 0;
  }
  .print_item{
    background-color: #fff;
    border-radius: $profilePageBorderRadius;
    padding: 30px;
    min-height: 210px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    .print_item_header{
      display: flex;
      align-items: flex-start;
      gap: 24px;
      .print_item_content{
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        .print_item_title{
          margin-bottom: 4px;
          font-size: 18px;
          font-weight: 600;
        }
        .print_item_size{
          font-size: 14px;
          font-weight: 400;
          color: #64748b;
          letter-spacing: .3px;
        }
      }
    }
    .print_item_footer{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .print_item_buttons{
        display: flex;
        align-items: center;
        gap: 10px;
        button{
          font-size: 14px;
          background-color: $blue;
          font-weight: 700;
          border-radius: 8px;
        }
      }
      .print_item_show{
        background-color: #F0F2F9;
        border-radius: 8px;
        svg{
          color: #272727;
        }
        &.darkEye{
          background-color: #fff;
          svg{
            color: #272727;
          }
        }
      }
    }
  }
}
