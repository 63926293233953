@import "../../assets/styles/variables";
.header {
    border-bottom: 1px solid #E5E5E5;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 9999;
    background-color: #fff;
    .header_items{
        padding: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .logo{
            width: 95px;
            margin-right: 40px;
        }
        .nav{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .menu_list{
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 16px;
                .menu_item{
                    font-weight: 400;
                    button{
                        text-transform: none;
                        color: $menuItemColor;
                        padding: 10px 15px;
                        letter-spacing: $letterSpaceing;
                        font-size: 16px;
                        font-weight: 400;
                        &.active{
                            background-color: $menuItemHoverBackgroundColor;
                        }
                        &.darkModeActive{
                            color: #fff;
                            background-color: #272727;
                        }
                    }
                    .account_menu_select{
                        position: relative;
                        .account_menu_select_items{
                            position: absolute;
                            top: 33px;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            background-color: #fff;
                            width: 175px;
                            box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgb(201 201 201);
                            border-radius: 8px;
                            padding: 8px;
                            opacity: 0;
                            visibility: hidden;
                            transition: .2s;
                            a{
                                width: 100%;
                                letter-spacing: 0;
                            }
                            &.open{
                                opacity: 1;
                                visibility: visible;
                            }
                        }
                    }
                }
            }
            .burger{
                cursor: pointer;
                display: none;
            }
        }
        .header_right{
            display: flex;
            align-items: center;
            .language{
                button, li{
                    font-size: 14px;
                    font-weight: 600;
                    color: #212121;
                }
                margin-right: 8px;
            }
            .header_right_btns{
                button{
                    font-weight: 600;
                    font-size: 14px;
                    letter-spacing: $letterSpaceing;
                    color: #fff;
                    border-radius: 7px;
                    background-color: $blue;
                    transition: .2s;
                    text-transform: capitalize;
                    &:hover{
                        background-color: #0760df;
                    }
                }
            }
        }
    }
}
//Responsive
@media screen and (max-width: 992px) {
    .header {
        .header_items {
            .nav {
                .menu_list{
                    display: none;
                }
                .burger {
                    display: block;
                }

            }
            .header_right{
                .header_right_btns{
                    display: none;
                }
            }
        }
    }
    .mobile_nav{
        padding: 24px!important;
        border-top: 1px solid #E5E5E5;
        .menu_list{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
            margin-bottom: 55px;
            .menu_item{
                width: 100%;
                button{
                    width: 100%;
                    text-align: start;
                    display: block;
                    color: rgba(0, 0, 0, .87);
                    text-transform: capitalize!important;
                }
            }
        }
        .header_right_button{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 600;
            font-size: 14px;
            letter-spacing: $letterSpaceing;
            color: #fff;
            border-radius: 7px;
            background-color: $blue;
            transition: .2s;
            text-transform: capitalize;
            padding: 10px;
        }
    }
}
