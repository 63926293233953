@import "../../../assets/mixins/adaptiveFontMixin";
@import "../../../assets/styles/variables";
.get-started{
  //height: 100vh;
  .get-started_title{
    @include adaptiv-font(36, 30);
    font-weight: 600;
    line-height: 40px;
    margin: 40px auto;
  }
  .get-started_form{
    gap: 24px;
    margin: 0 auto 40px;
    .get-started_form-check{
      .MuiFormControlLabel-label{
        font-size: 14px;
      }

    }
    .react-international-phone-input-container{
      .react-international-phone-country-selector{
        width: 20%;
        .react-international-phone-country-selector-button{
          width: 100%;
          padding: 23px 5px;
          border-width: 1px;
          border-right-width: 0;
          border-bottom-left-radius: 7px;
          border-top-left-radius: 7px;
          transition: .2s;
          &:focus{
            border-color: #86b7fe;
            outline: 0;
            box-shadow: rgba(30, 144, 250, .7) 0 0 0 0.125rem;
          }
          &:hover{
            border-color: $blue;
            background-color: transparent;
            border-right-width: 1px;
          }
          .react-international-phone-country-selector-button__button-content{
            .react-international-phone-flag-emoji{
              width: 19px;
            }
          }

        }
      }
      .react-international-phone-input{
        padding: 23px 10px;
        width: 80%;
        border-width: 1px;
        border-bottom-right-radius: 7px;
        border-top-right-radius: 7px;
        font-size: 15px;
        transition: .2s;
        &:focus{
          border-color: #86b7fe;
          outline: 0;
          box-shadow: rgba(30, 144, 250, .7) 0px 0px 0px 0.125rem;
        }
        &:hover{
          border-color: $blue;
        }

      }
    }
    .get-started_form_input{
      padding: 23px 11px;
      border-width: 1px;
      border-radius: 7px;
      height: 0;
      &:focus{
        border-color: #86b7fe;
        outline: 0;
        box-shadow: rgba(30, 144, 250, .7) 0px 0px 0px 0.125rem;
      }
      &:hover{
        border-color: $blue;
      }
    }
    .get-started_form-password{
      position: relative;
      .error-text{
        color: #ff0000!important;
      }
      .eye{
        position: absolute;
        top: 0;
        bottom: 0;
        right: 10px;
        margin: auto;
        cursor: pointer;
        transition: .2s;
        &:hover{
          color: $blue;
        }
      }
    }
    .get-started_form_forgot-password_block{
      display: flex;
      justify-content: space-between;
      align-items: center;
      .forgot-password{
        font-size: 14px;
        color: $blue;
        line-height: 20px;
        font-weight: 600;
        text-decoration: none;
        text-transform: capitalize;
      }
    }
    .send-button{
      padding: 12px;
      font-weight: 600;
      margin-bottom: 40px;
      background-color: $blue;
      &.disabled{
        background-color: #8a8a8a;
        cursor: not-allowed;
      }
    }
    .dont-have-account{
      font-size: 16px;
      display: flex;
      justify-content: center;
      button{
        font-size: 14px;
        font-weight: 600;
        text-decoration: none;
        padding: 0;
        text-transform: uppercase;
      }
    }
  }
}
.react-international-phone-input--disabled{
  background-color: #F5F5F5!important;
  &:hover{
    border-color: transparent!important;
  }
}

.react-international-phone-country-selector-button--disabled{
  background-color: #F5F5F5!important;
  &:hover{
    border-color: transparent!important;
  }
}

