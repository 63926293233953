@import "../../../../assets/styles/variables";
.transaction_item{
  td{
    font-size: 14px;
    &:first-child{
      padding: 16px 16px 16px 32px;
    }
  }
  .transaction_item_icon{
    img{
      width: 30px;
      height: 30px;
    }
  }
  .transaction_item_amount{
    font-weight: 700;
    &.payout{
      color: #d51e1e;
    }
    &.payIn{
      color: #519D62;
    }
  }
  .transaction_item_comment{
    svg{
      &:first-child{
        margin-left: 0;
      }
      margin-left: 10px;
    }
  }
  .transaction_item_status{
    span{
      border-radius: 8px;
      padding: 10px;
      width: fit-content;
      height: 32px;
      &.success{
        color: #519D62;
      }
      &.failed{
        color: #ff0000;
      }
      &.pending{
        color: #e8a908;
      }
    }
  }
  .transaction_repeat{
    a{
      font-size: 16px;
      color: $blue;
      font-weight: 600;
    }
  }
}