@import "../../assets/styles/variables";
.payment{
  //background-color: $sectionBGColor;
  .payment_component{
    //background: rgb(33,33,35);
    //background: radial-gradient(circle, rgba(33,33,35,1) 0%, rgba(41,41,41,1) 100%);
    background-color: #F0F2F9;
    border-radius: $profilePageBorderRadius;
    max-width: 400px;
    padding: calc(20px + 20 * ((100vw - 320px) / (1860 - 320))) calc(18px + 14 * ((100vw - 320px) / (1860 - 320)));
    margin: auto;
    .payment_bar{
      .user_welcome_text{
        color: $blue;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        word-break: break-all;
      }
      span{
        font-size: 14px;
        display: flex;
      }
    }
    .payment_component_header{
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding-bottom: 20px;
      //border-bottom: 1px solid #E0E0E0;
      .user_image, .user_company_logo{
        width: 72px;
        height: 72px;
        min-width: 72px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        position: relative;
        overflow: hidden;
        .user_rating{
          position: absolute;
          background-color: #fff;
          //background-color: #5E5E61;
          border-radius: 5px;
          bottom: 0;
          right: 0;
          padding: 3px;
          font-size: 9px;
          display: flex;
          align-items: center;
          justify-content: center;
          svg{
            color: #FAAF00;
            font-size: 11px;
          }
        }
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .user_data {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 20px;
        .user_name {
          font-size: 18px;
          font-weight: 400;
          line-height: 28px;
        }
        .user_profession{
          //color: #0f172a;
          color: #000;
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
        }

      }
    }
    .payment_data{
      margin-top: 12px;
      .payment_data_title{
        color: #000;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        font-weight: 600;
        margin-bottom: 16px;
      }
      .payment_elements{
        button{
          background-color: #F0F2F9;
        }
        .minimum_amount{
          color: #ff5252;
          font-size: 12px;
          font-weight: 400;
        }
        .price_element{
          display: flex;
          justify-content: space-between;
          gap: 12px;
          margin-bottom: 12px;
          button{
            border: none;
            border-radius: 8px;
            padding: 4px 10px;
            height: 65px;
            width: 65px;
            font-size: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #6E6F72;
          }
          .price_input{
            margin-bottom: 8px;
            position: relative;
            max-width: 190px;
            input{
              text-align: center;
              font-size: 24px;
              font-weight: 600;
              line-height: 32px;
              min-height: 65px;
              //border: 1px solid #94a3b8;
              border: 0.5px solid rgba(255, 255, 255, 0.07);
              //background-color: #5E5E61;
              background-color: #F0F2F9;
              //color: #fff;
              color: #000;
              border-radius: 6px;
              width: 100%;
              padding: 0!important;
              //margin-bottom: 8px;
              &::-webkit-outer-spin-button,
              &::-webkit-inner-spin-button {
                display: none;
              }
            }
          }
          .custom_price_btn{
            border-radius: 8px;
            font-weight: 600;
            height: 40px;
            font-size: 17px;
            //color: #ffffff !important;
            //background-color: #5E5E61;
            color: #000!important;
            background-color: #F0F2F9;
            border: 0.5px solid rgba(255, 255, 255, 0.07);
          }
        }
        .rating_elements{
          margin-bottom: 20px;
          .payment_data_title{
            margin-bottom: 8px;
            color: #000;
          }
          .stars{
            span{
              margin: 0 auto;
              display: flex;
              align-items: center;
              justify-content: center;
              label{
                margin: 0 8px;
                svg{
                  font-size: 30px;
                  //color: #94A3B8;
                }
              }
            }
          }
          .rating_comment{
            margin-top: 20px;
            width: 100%;
            textarea {
              resize: none;
              overflow-y: hidden;
              min-height: 60px;
              border: 1px solid #94A3B8;
              border-radius: 4px;
              padding: 10px 15px;
              width: 100%;
              background-color: #fff;
              color: #000;
              &:focus{
                border-width: 2px;
                border-color: $blue!important;
              }
              &::placeholder{
                color: #808080;
              }
            }
          }
        }
        .survey{
          margin-bottom: 20px;
          .survey_items{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: calc(0px + 7 * ((100vw - 320px) / (1860 - 320)));
            button{
              background-color: transparent!important;
              display: flex;
              flex-direction: column;
              align-items: center;
              font-size: 13px;
              text-align: center;
            }
            &::-webkit-scrollbar{
              display: none;
            }
          }
        }
      }
      .terms{
        margin-top: 8px;
        font-size: 12px;
        text-align: center;
        a{
          text-decoration: underline;
          color: $blue;
        }
      }
    }
  }
}