@import "../../../assets/styles/variables";
.profile_transactions{
  padding: 0 0 0 24px;
  .profile_transactions_content{
    background-color: #fff;
    border-radius: $profilePageBorderRadius;
    .profile_transactions_content_title{
      font-size: 20px;
      font-weight: 500;
      padding: 40px 32px 16px;
    }
    table{
      box-shadow: none;
      thead{
        tr{
          background-color: #F5F8FC;
          th{
            font-size: 12px;
            font-weight: 700;
            color: rgb(100, 116, 139);
            &:first-child{
              padding: 16px 16px 16px 32px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px){
  .profile_transactions{
    padding: 0;
    .profile_transactions_content{
      margin-top: 15px;
    }
  }
}