@import "../../assets/styles/variables";
@import "../../assets/mixins/adaptiveFontMixin";
.business_owners{
  background-color: $sectionBGColor;
  .text_block{
    .business_owners_title, .business_owners_subtitle{
      margin-bottom: 28px;
    }
    .text_block_list{
      list-style: disc;
      padding-left: 24px;
      margin-bottom: 32px;
    }
    .text_block_bottom{
      color: #475569;
      letter-spacing: .2px;
      line-height: calc(18px + 6 * ((100vw - 320px) / (1860 - 320)));;
      @include adaptiv-font(16, 12);
      margin-top: 56px;
    }
  }
  .business_owners_image{
    img{
      height: 570px;
      object-fit: contain;
    }
  }
}
//Responsive
@media screen and (max-width: 960px) {
  .business_owners{
    .text_block{
      width: 100%;
    }
    .business_owners_image{
      display: none!important;
    }
  }
}