@import "../../../assets/styles/variables";
.profile_edit{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .profile_image{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    .avatar{
      border-radius: 100%;
      overflow: hidden;
      max-width: 366px;
      max-height: 366px;
      img{
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    .edit_btns{
      position: absolute;
      bottom: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 80%;
      margin: 0 auto;
      .edit_btn{
        button{
          background-color: #0666EB;
          border-radius: 100px;
          color: #fff;
          min-width: 0!important;
        }
      }
    }
  }
  .profile_avatar{
    background-color: #fff;
    padding: 40px 44px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: $profilePageBorderRadius;
    position: relative;
  }
  .profile_content_edit{
    padding: 0 12px 0 24px;
    .profile_content_edit_tabs{
      border-bottom: 1px solid #E2E8F0;
      .profile_content_edit_tabs_items{
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 450px;
        .profile_content_edit_tab{
          font-size: 14px;
          font-weight: 600;
          //padding: 18px;
          width: 90px;
          height: 47px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          color: #6E6F84;
          transition: .3s;
          border-bottom: 2px solid transparent;
          &:hover{
            background-color: #EAECF3;
          }
          &.active{
            color: $blue;
            border-bottom-color: $blue;
            &:hover{
              background-color: #D4E1F7;
            }
          }
        }
      }
    }
    .profile_content_edit_body{
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-top: 24px;
      padding: 40px calc(11px + 21 * ((100vw - 320px) / (1860 - 320)));
      border-radius: $profilePageBorderRadius;
      .profile_edit_content_title{
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
        margin-bottom: 30px;
      }
      .profile_content_edit_items{
        .profile_content_edit_item_title{
          font-size: 18px;
          color: #64748b;
        }
        .profile_edit_content_subtitle{
          font-size: 14px;
          margin-bottom: 16px;
        }
        .profile_content_edit_phone{
          input[type="tel"], button{
            transition: 0s!important;
            border-color: #C4C4C4!important;
            &:hover{
              border-color: #000!important;
            }
          }
        }
        .profile_content_edit_item{
          width: 100%;
          margin-bottom: 14px;
          input{
            padding: 23px 11px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px){
  .profile_edit{
    .profile_content_edit{
      padding: 0 6px 112px;
    }
  }
}