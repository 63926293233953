@import "../../assets/styles/variables";
.do_you_want_to_accept_tips{
  .do_you_want_to_accept_tips_items{
    display: flex;
    background-color: $blue;
    color: #fff;
    border-radius: 20px;
    overflow: hidden;
    .do_you_want_to_accept_tips_content{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 calc(30px + 154 * ((100vw - 320px) / (1860 - 320)));
      text-align: center;
      .btn{
        background-color: #fff;
        font-size: 16px;
        font-weight: 600;
        padding: 6px 25px;
        color: #0F172A;
        border-radius: 8px;
        transition: .2s;
        display: inline-flex;
        width: fit-content;
        margin-top: 40px;
        &:hover{
          background-color: #efefef;
        }
      }
    }
    .do_you_want_to_accept_tips_image{
      border-radius: 20px;
      overflow: hidden;
      img{
        height: 530px;
        object-fit: cover;
      }
    }
  }
}

//Responsive
@media screen and (max-width: 768px){
  .do_you_want_to_accept_tips{
    .do_you_want_to_accept_tips_items{
      flex-direction: column-reverse;
      .do_you_want_to_accept_tips_content{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 40px 0;
        &:nth-child(1){
          text-align: center;
        }
        //.do_you_want_to_accept_tips_title{
        //  margin-top: 40px;
        //}
      }
      .do_you_want_to_accept_tips_image{
        width: 100%;
      }
    }
  }
}
@media screen and (max-width: 600px){
  .do_you_want_to_accept_tips{
    .do_you_want_to_accept_tips_items{
      .do_you_want_to_accept_tips_image{
        display: none;
      }
    }
  }
}