@import "../../../assets/mixins/adaptiveFontMixin";
.bannerContainer {
  margin-bottom: calc(80px + 152 * ((100vw - 320px) / (1860 - 320)));
  .main_title {
    @include adaptiv-font(60, 40);
    font-weight: 600;
    line-height: 72px;
  }
  .main_subtitle{
    @include adaptiv-font(18, 14);
    font-weight: 400;
    color: #334155;
    margin: 24px 0 48px;
  }
}