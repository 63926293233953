@import "../../../assets/styles/variables";
@import "../../../assets/mixins/adaptiveFontMixin";
.profile {
  //background-color: $sectionBGColor;
  .profile_data {
    background-color: #fff;
    border-radius: $profilePageBorderRadius;
    padding: 24px;
    margin: 24px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .profile_data_content{
      display: flex;
      align-items: center;
      gap: 16px;
      .profile_data_content_avatar{
        position: relative;
        .profile_rating{
          position: absolute;
          background-color: #fff;
          border-radius: 10px;
          bottom: 0;
          right: 0;
          padding: 3px;
          font-size: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          svg{
            color: #FAAF00;
            font-size: 11px;
          }
        }
      }
      .profile_image{
        width: 50px;
        height: 50px;
        border-radius: 100px;
        overflow: hidden;
        img{
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
      .profile_data_content_text{
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        img{
          width: 22px;
        }
      }
      .profile_image_edit{
        cursor: pointer;
        position: relative;
        border-radius: 100px;
        transition: .2s;
        button{
          background-color: $blue;
          min-width: fit-content;
          border-radius: 100px;
          padding: 6px;
        }
      }
    }
  }
}