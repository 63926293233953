@import "../../../../assets/styles/variables";
.profile_transactions{
  padding: 0 0 0 24px;
  .profile_transactions_content{
    background-color: #fff;
    border-radius: $profilePageBorderRadius;
    .profile_transactions_content_title{
      font-size: 20px;
      font-weight: 500;
      padding: 40px 32px 16px;
    }
    table{
      box-shadow: none;
      thead{
        tr{
          background-color: #F5F8FC;
          th{
            font-size: 12px;
            font-weight: 700;
            color: rgb(100, 116, 139);
            &:first-child{
              padding: 16px 16px 16px 32px;
            }
          }
        }
      }
    }
  }
}

.average_rating{
  display: flex;
  align-items: center;
  background-color: #F5F8FC;
  border-radius: 8px;
  padding: 20px;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 32px;
  .average_rating_icon{
    width: 34px;
    height: 34px;
    margin-right: 12px;
    img{
      width: 100%;
    }
  }
  .average_rating_text{
    span{
      font-weight: 700;
    }
  }
}

@media screen and (max-width: 768px){
  .profile_transactions{
    padding: 0;
    .profile_transactions_content{
      margin-top: 15px;
    }
  }
}