@import "../../assets/mixins/adaptiveFontMixin";
.where_to_setup_qr_code {
  .where_to_setup_qr_code_items {
    margin-top: 50px;
    gap: 25px;
    justify-content: center;
    .where_to_setup_qr_code_item {
      border-radius: 14px;
      overflow: hidden;
      background-color: #F0F2F9;
      max-width: 448px;
      .where_to_setup_qr_code_item_image {
        border-radius: 14px;
        overflow: hidden;
        height: 75%;
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .where_to_setup_qr_code_item_text{
        background-color: #F0F2F9;
        padding: 30px;
        @include adaptiv-font(24, 16);
        font-weight: 700;
      }
    }
  }
}