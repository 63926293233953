@import "../../../assets/styles/variables";
.MuiTabPanel-root{
  .MuiBox-root{
    .react-international-phone-input-container{
      .react-international-phone-country-selector{
        width: 20%;
        .react-international-phone-country-selector-button{
          width: 100%;
          padding: 23px 5px;
          border-width: 1px;
          border-right-width: 0;
          border-bottom-left-radius: 7px;
          border-top-left-radius: 7px;
          transition: .2s;
          &:focus{
            border-color: #86b7fe;
            outline: 0;
            box-shadow: rgba(30, 144, 250, .7) 0 0 0 0.125rem;
          }
          &:hover{
            border-color: $blue;
            background-color: transparent;
            border-right-width: 1px;
          }
          .react-international-phone-country-selector-button__button-content{
            .react-international-phone-flag-emoji{
              width: 19px;
            }
          }
        }
      }
      .react-international-phone-input{
        padding: 23px 10px;
        width: 80%;
        border-width: 1px;
        border-bottom-right-radius: 7px;
        border-top-right-radius: 7px;
        font-size: 15px;
        transition: .2s;
        &:focus{
          border-color: #86b7fe;
          outline: 0;
          box-shadow: rgba(30, 144, 250, .7) 0px 0px 0px 0.125rem;
        }
        &:hover{
          border-color: $blue;
        }
      }
    }
  }
}
.css-h0q0iv-MuiButtonBase-root-MuiTab-root{
  min-height: 53px!important;
}