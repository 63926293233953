@import "../../../assets/mixins/adaptiveFontMixin";
@import "../../../assets/styles/variables";
.our_vision_and_mission{
  .our_vision_and_mission_desc{
    margin: 40px 0 80px;
  }
  .our_vision_and_mission_items{
    margin-top: 40px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 24px;
    .our_vision_and_mission_item{
      display: flex;
      align-items: flex-start;
      gap: 24px;
      background-color: $sectionBGColor;
      border-radius: 20px;
      padding: 20px 30px 50px;
      color: #0f172a;
      @include adaptiv-font(18, 14);
      font-weight: 400;
      line-height: calc(20px + 8 * ((100vw - 320px) / (1860 - 320)));
      .our_vision_and_mission_item_img{
        width: 32px;
        height: 32px;
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}