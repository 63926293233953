@import "../../assets/styles/variables";
.contact{
  color: #475569;
  border-radius: $profilePageBorderRadius;
  display: flex;
  align-items: flex-start;
  .map{
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    overflow: hidden;
  }
  .contact_form{
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    padding: 60px 60px 40px 60px;
    .contact_title{
      font-size: 34px;
      font-weight: 900;
      margin-bottom: 16px;
    }
    .send_button{
      background-color: $blue!important;
      border-radius: 8px;
      text-transform: capitalize;
    }
    .contact_bottom{
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-top: 40px;
      .contact_bottom_item{
        .image{
          width: 70px;
          height: 70px;
          border-radius: 100px;
          background-color: #F0F2F9;
          display: flex;
          justify-content: center;
          align-items: center;
          img{
            width: 26px;
          }
        }
        .contact_bottom_item_text{
          font-size: 12px;
          font-weight: 400;
          line-height: 20px;
          margin-top: 20px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 5px;
        }
      }
    }
  }
}