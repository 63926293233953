@import "../../assets/mixins/adaptiveFontMixin";
.bannerContainer {
  //margin-top: 80px;
  .main_title {
    @include adaptiv-font(60, 40);
    font-weight: 600;
    line-height: calc(49px + 23 * ((100vw - 320px) / (1860 - 320)));
  }
  .main_subtitle{
    @include adaptiv-font(18, 14);
    font-weight: 400;
    color: #334155;
    margin: 24px 0 48px;
  }
  .bannerImage{
    img{
      height: 100%;
      max-height: 650px;
      object-fit: contain;
      width: 100%;
    }
  }
}

@media screen and (max-width: 992px) {
  .bannerImage{
    display: none;
  }
}