.notification{
  padding: 15px 20px;
  border-radius: 8px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  width: 100%;
  //position: fixed;
  //bottom: 10px;
  //right: 10px;
  //transform: translateX(350px);
  //transition: .3s;
  //&.active{
  //  transform: translateX(0px);
  //}
}